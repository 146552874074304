// 访谈基本信息
<template>
  <div>
    <!-- <base-form
      :componentList="interviewBasicForm"
      :showBtns="false"
      :formAttrs="{
        model: currentData,
        labelWidth: '150px',
      }"
      class="formStyle"
      ref="formDataList"
    /> -->
    <form-info
      :componentList="interviewBasicForm"
      :model="currentData"
      :showbor="true"
      :count="count"
    ></form-info>
  </div>
</template>
<script>
import { interviewBasicConfig } from '../utils/make-config'

import { getDictLists } from '@/filters/fromDict'
import FormInfo from '@/components/packages/form-info/form-info.vue'

export default {
  props: {
    financingApplicationData: Object,
    getCurrentData: Object
  },
  components: { FormInfo },
  data () {
    return {
      count: 0,
      providertData: [],
      typelist: [] // 资金方下拉
      // currentData: {
      //   summaryNum: '测试1'
      // } // 当前表单数据
    }
  },
  computed: {
    currentData () {
      return this.getCurrentData
    },
    interviewBasicForm () {
      return interviewBasicConfig(this)
    },
    // 合作类型字典
    cooperationType () {
      return getDictLists('COMPANY_COOPERATION_TYPE').map((item) => {
        return { value: item.dictId, label: item.dictName }
      })
    }
  },
  watch: {
    currentData (val) {
      if (val) {
        this.$nextTick(() => {
          this.count++
        })
      }
    }
  },
  created () {},
  mounted () {},

  methods: {
    // 校验
    validateFinancingApplication () {
      let flag = false
      this.$refs.formDataList.validate((valid) => {
        flag = valid
      })
      return flag
    }
  }
}
</script>
