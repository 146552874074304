<template>
  <div class="formcontent">
    <el-row>
      <el-col
        v-bind="colAttrs(item)"
        v-for="(item, index) of componentList"
        :key="index"
      >
        <div class="item" :class="{ bor: showbor }" v-show="!item.isHidden">
          <div class="label">{{ item.label }}：</div>
          <div v-if="item.tag" :style="item.style">
            <component
              :is="item.tag"
              ref="valuebox"
              v-bind="{ ...item.attrs }"
              v-model="model[item.prop]"
            ></component>
          </div>

          <el-tooltip
            v-if="!item.tag"
            effect="dark"
            :content="
              item.type
                ? format(item.type, model[item.prop])
                : model[item.prop] + ''
            "
            placement="top"
            :disabled="disabledarr[index]"
            :enterable="false"
          >
            <div
              class="value"
              ref="valuebox"
              :class="{ showAll: item.showAll }"
            >
              {{
                item.type
                  ? format(item.type, model[item.prop])
                  : model[item.prop]
              }}
            </div>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export const DEFAULT_COLS = { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
export default {
  components: {},
  name: 'formInfo',
  props: {
    componentList: {
      type: Array,
      default: () => []
    },
    model: {
      type: Object,
      default: () => {}
    },
    showbor: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      disabledarr: [],
      dom: []
    }
  },
  computed: {},
  watch: {
    count: {
      handler (val) {
        this.$nextTick(() => {
          this.getwidth()
        })
      },
      deep: false
    }
  },
  mounted () {
    // this.getwidth()
  },
  methods: {
    download (data) {
      this.$emit('download', data)
    },
    colAttrs (it) {
      const { span } = it
      if (typeof span !== 'undefined') {
        return {
          span
        }
      } else {
        return it.cols || DEFAULT_COLS
      }
    },
    // 获取内容宽度
    getwidth () {
      this.dom = this.$refs.valuebox
      this.dom.forEach((item, index) => {
        this.$set(
          this.disabledarr,
          index,
          item.offsetWidth >= item.scrollWidth
        )
      })
      console.log(this.disabledarr)
    },
    // 数据格式处理
    format (type, data) {
      // console.log(data === true, 'xxxx')
      if (data && data !== 0) {
        if (type === 'Amount') {
          return data.toLocaleString('en-US', { minimumFractionDigits: 2 })
        } else if (type === 'Date') {
          return (
            new Date(data).getFullYear() +
            '-' +
            (new Date(data).getMonth() + 1) +
            '-' +
            new Date(data).getDate()
          )
        } else {
          return data
        }
      } else {
        return '--'
      }
    }
  }
}
</script>
<style lang="scss" src='./form-info.scss' scoped></style>
